<template>
  <v-container>
    <DashboardHeader :icon="headerIcon" :title="headerTitle" />
    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="requests"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      @click:row="viewProfile"
      class="custom-shadow custom-radius"
    >
      <template slot="top">
        <v-row justify="end" no-gutters>
          <v-toolbar class="custom-radius" color="#fafafa" dense flat>
            <v-toolbar-title class="subtitle-1">
              {{title}}
              <v-tooltip top v-if="!isToggled && requests.length && !loading">
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="{name: 'myProfile'}"
                    class="mr-3 mb-1"
                    color="info"
                    depressed
                    icon
                    small
                    style="text-decoration: none"
                    v-on="on"
                  >
                    <v-icon>mdi-alert-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{alertBtnText}}</span>
              </v-tooltip>
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  :class="{toggled: isToggled}"
                  :color="toggleButtonColor"
                  @click="isToggled = !isToggled"
                  class="mr-0"
                  depressed
                  fab
                  small
                  v-on="on"
                >
                  <v-icon>{{isToggled ? 'mdi-account-arrow-right': 'mdi-account-arrow-left'}}</v-icon>
                </v-btn>
              </template>
              <span>මාරු කරන්න</span>
            </v-tooltip>
          </v-toolbar>
        </v-row>
      </template>
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
    </v-data-table>
  </v-container>
</template>

<script>
import { ApiService } from "../services/api.service";
import { CHANGE_LOADING_STATE } from "../store/mutation-types";
import DashboardHeader from "../components/DashboardHeader";

export default {
  components: {
    DashboardHeader
  },

  data: () => ({
    isToggled: false,
    loading: false,
    headers: [
      { text: "සාමාජික අංකය", value: "to", sortable: false },
      { text: "දිනය", value: "created_at" }
    ],
    options: {},
    requests: [],
    total: 0,
  }),

  async mounted() {
    await this.fetchRequests(true);
  },

  computed: {
    headerTitle: function () {
      if (this.$route.path == "/dashboard/photo-requests") {
        return "ෆොටෝ ඇරයුම්";
      } else if (this.$route.path == "/dashboard/horoscope-requests") {
        return "හඳහන් ඇරයුම්";
      } else {
        return "";
      }
    },
    headerIcon: function () {
      if (this.$route.path == "/dashboard/photo-requests") {
        return "mdi-camera-account";
      } else if (this.$route.path == "/dashboard/horoscope-requests") {
        return "mdi-folder-account";
      } else {
        return "";
      }
    },
    title: function () {
      if (this.isToggled) {
        return "යොමුකල ඇරයුම්";
      } else {
        return "ලැබුණු ඇරයුම්";
      }
    },
    toggleButtonColor: function () {
      if (this.isToggled) {
        return "secondary";
      } else {
        return "accent";
      }
    },
    alertBtnText: function () {
      if (this.$route.path == "/dashboard/photo-requests") {
        return "පැතිකඩ පින්තූරය එකතු කරන්න";
      } else if (this.$route.path == "/dashboard/horoscope-requests") {
        return "ගිණුමේ හඳහන් විස්තර සම්පූර්ණ කරන්න";
      } else {
        return "";
      }
    }
  },

  watch: {
    $route(to, from) {
      this.options.page = 1;
      this.isToggled = false;
      this.fetchRequests(true);
    },
    options: {
      handler: function () {
        this.fetchRequests();
      },
      deep: true,
    },
    isToggled: {
      handler: function () {
        this.fetchRequests();
      },
    }
  },

  methods: {
    // Fetch photo requests
    fetchRequests: async function (isMounting = false) {
      if (isMounting) {
        this.$store.commit(CHANGE_LOADING_STATE, { active: true });
      }
      this.loading = true;

      const type = this.$route.path == '/dashboard/photo-requests' ? 'photo' : 'horoscope'; // Request type

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const order = sortDesc[0] ? 'desc' : 'asc';

      const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};
      const sortOptions = sortBy.length != 0 ? { sortBy: sortBy[0], order: order } : {};

      try {
        const url = "customers/get-requests";
        const params = { params: { type: type, ...pageOptions, ...sortOptions } };
        const response = await ApiService.get(url, params);

        if (this.isToggled) {
          this.requests = response.data.sent.data;
          this.total = response.data.sent.total;
          this.headers[0].value = "to"; // Set the receiver username
        } else {
          this.requests = response.data.received.data;
          this.total = response.data.received.total;
          this.headers[0].value = "from"; // Set the receiver username
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;

      if (isMounting) {
        this.$store.commit(CHANGE_LOADING_STATE, { active: false });
      }
    },

    // View profile of request
    viewProfile: function (row) {
      let memberId = row.from;

      if (this.isToggled) {
        memberId = row.to;
      }
      this.$router.push(`members/${memberId}`);
    }
  }
}
</script>